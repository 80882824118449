import React from "react";

import Helmet from "react-helmet";

export default (props) => (
  <Helmet 
    defaultTitle={props.site.siteMetadata.title}
    titleTemplate={`%s – ${props.site.siteMetadata.title}`}
    >
    <html lang="en" />
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />

    <link href="https://fonts.googleapis.com/css?family=IBM+Plex+Mono:300,600|IBM+Plex+Sans+Condensed:600|IBM+Plex+Sans:400,400i,500|IBM+Plex+Serif:400,400i,500&amp;subset=latin-ext" rel="stylesheet" />

    <script defer src="/vendor/fontawesome/fa-solid.min.js"></script>
    <script src="/vendor/fontawesome/fontawesome.min.js"></script>

    <meta name="title" content={props.site.siteMetadata.title} />
    <meta name="description" content={props.site.siteMetadata.description} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://local-memory.org" />
    <meta property="og:title" content={props.site.siteMetadata.title} />
    <meta property="og:description" content={props.site.siteMetadata.description} />
    <meta property="og:image" content="https://local-memory.org/images/content/p/PopMusicAustin.jpg" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://local-memory.org" />
    <meta property="twitter:title" content={props.site.siteMetadata.title} />
    <meta property="twitter:description" content={props.site.siteMetadata.description} />
    <meta property="twitter:image" content="https://local-memory.org/images/content/p/PopMusicAustin.jpg" />
  </Helmet>
)