import React from "react";
import { Link } from "gatsby";

export default (props) => (
  <footer>
    <div className="wrapper-width">
      <div className="simpleflex-grid">
        <div className="cell">
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/notes">Notes</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/about#newsletter-signup">Newsletter</Link></li>
          </ul>
        </div>
      </div>
      <div className="simpleflex-grid">
        <div className="cell">
          <p>
            <Link to="/">{props.site.siteMetadata.title}</Link> is a project of the <a href="https://texasmusicdocs.github.io/">Texas Music Documentation Project</a>.
          </p>
        </div>
        <div className="cell">

        </div>
      </div>
    </div>
    <script async defer src="https://cdn.simpleanalytics.io/hello.js"></script>
    <noscript><img src="https://api.simpleanalytics.io/hello.gif" alt="" /></noscript> 
  </footer>
);