import React from "react";

export default class PageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.classNames = "page-container";

    if (this.props.topPadding) {
      this.classNames += ` top-padding-${this.props.topPadding}`;
    }
    if (this.props.bottomPadding) {
      this.classNames += ` bottom-padding-${this.props.bottomPadding}`;
    }
  }

  render() {
    return (
      <div className={this.classNames}>{this.props.children}</div>
    );
  }
}