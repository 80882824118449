import React from "react";

export default class PageSection extends React.Component {
  constructor(props) {
    super(props);

    this.wrapContent = this.wrapContent.bind(this);
  }

  wrapContent() {
    let wrapperClass = 'content-width';

    switch (this.props.wrapperType) {
      case 'wrapper':
        wrapperClass = 'wrapper-width';
        break;
      default:
        break;
    }

    return (
      <div className={wrapperClass}>
        {this.props.children}
      </div>
    );
  }

  render() {
    let classNames = 'page-section';

    if (this.props.topPadding) {
      classNames += ` top-padding-${this.props.topPadding}`;
    }

    if (this.props.bottomPadding) {
      classNames += ` bottom-padding-${this.props.bottomPadding}`;
    }

    if (this.props.background) {
      switch (this.props.background) {
        case 'dark-accent':
          classNames += ' dark-accent-background';
          break;
        case 'light-accent':
          classNames += ' light-accent-background';
          break;
        case 'dark-gray':
          classNames += ' dark-gray-background';
          break;
        case 'gray':
          classNames += ' gray-background';
          break;
        default:
          break;
      }
    }

    return (
      <section className={classNames}>
        {this.wrapContent()}
      </section>
    );
  }
}
