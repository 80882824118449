import React from 'react';

export const ArrowSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1000px" height="1000px" viewBox="0 0 1000 1000">
    <path d="M10,500h306.3v490h367.5V500H990L500,10L10,500z"/>
  </svg>
);

export const HamburgerSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.18 113.99">
      <rect width="143.18" height="24"/>
      <rect y="45" width="143.18" height="24"/>
      <rect y="90" width="143.18" height="24"/>
  </svg>
);
